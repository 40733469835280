import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, withRouter } from 'react-router';
import { makeId } from '../../helpers/func';
import { BASE_URL } from '../../variables/api';
import axios from 'axios';
import ImageHighlightComponent from '../questions/ImageHighlightComponent';
import FillTheBlank from '../questions/FillTheBlank';
import FileUploadEnhanced from '../questions/FileUploadEnhanced';
import AudioComponent from '../questions/AudioComponent';
import VideoComponent from '../questions/VideoComponent';
import EssayComponent from '../questions/EssayComponent';
import TrueOrFalse from '../questions/TrueOrFalse';
import LabelQComponent from '../questions/LabelQComponent';
import OrderComponent from '../questions/OrderComponent';
import MCQComponent from '../questions/MCQComponent';
import Swal from 'sweetalert2';
import $ from "jquery";
import "../../css/DashBoard.css";
import "../../css/assessment.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import { NavLink } from 'react-router-dom';
import ProcessCardSingleState from '../questions/ProcessCardSingleState';
import MatchQComponent from '../questions/MatchQComponent';
import { MathJax } from 'better-react-mathjax';

function format(date: any, f: any) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

const getToday = () => {
    var now = new Date();
    var offset = now.getTimezoneOffset() * 60000;
    var adjustedDate = new Date(now.getTime() - offset);
    var formattedDate = adjustedDate.toISOString().substring(0, 16); // For minute precision
    return formattedDate;
};

const assessmentComponent = () => {
    const location = useLocation();
    const history = useHistory();

    // mapDispatchToProps


    const AddAssessment = (data: any) => {
        Swal.showLoading();

        if (!state.name) {
            Swal.fire("Title is required", "", "error");
            return;
        }


        axios.post(`${BASE_URL}admin/addexam.php`,
            data
        ).then((res: any) => {
            Swal.fire("Added Successfully", "", "success").then((x: any) => {
                onConfirm()
            })
        }).catch((error: any) => {
            Swal.fire("Something went wrong , please check the internent and try again ", "", "error");
        })
    };
    const DeleteQuestion = (id: any) => {
        let tempQuestions = [...state.questions]
        setState((prev: any) => ({
            ...prev,
            questions: [...tempQuestions.filter((q: any) => q.id != id)]
        }))
    };

    const GetAssessment = (id: any) => {

        axios.post(`${BASE_URL}admin/getexam.php`, {
            id: id
        }).then((res: any) => {
            setState((prev: any) => ({ ...prev, ...res.data.data }));
            SetCourseUtils((prev: any) => ({ ...prev, coursestandards: res.data.data.coursestandards }))
            GetCategoriesTeachersPeriods(res.data.data.coursegroup_id)
        }).catch((err: any) => {
            Swal.fire("Something went wrong , please check the internent and refresh the page ", "", "error");

        })
    };
    const UpdateAssessment = (data: any) => {
        Swal.showLoading()
        axios.post(`${BASE_URL}admin/updateexam.php`,
            data
        ).then((res: any) => {
            Swal.fire("Updated Successfully", "", "success").then((x: any) => {
                onConfirm()
            })
        }).catch((error: any) => {
            Swal.fire("Something went wrong please check the internent and try again ", "", "error");
        })
    };





    const GetCategoriesTeachersPeriods = (id: any) => {
        Swal.showLoading();
        axios.get(`${BASE_URL}admin/get_teachers_periods_categories.php?id=${id}`).then((res: any) => {
            SetCourseUtils({
                teachers: res.data.data.teachers?.map((teacher: any) => {
                    return {
                        value: teacher.id,
                        label: teacher.displayname,
                        username: teacher.officialemail?.toLowerCase()
                    }
                }),
                periods: res.data.data.periods,
                categories: res.data.data.categories,
                coursestandards: res.data.data.coursestandards,
            });
            Swal.close();

        }).catch((err) => {
            Swal.close();
            Swal.fire("Something went wrong , please check the internent and try again ", "", "error");
        })

    };


    const searchParams: any = new URLSearchParams(location.search);
    const examId = searchParams.get("id");
    const groupId = searchParams.get("course_id");
    const categoryId = searchParams.get("category_id");
    const copy_assessment = searchParams.get("copy");
    const periodId = searchParams.get("period_id");
    const assesmentTitle = searchParams.get("assessment_title")
    const assessment_prefix = assesmentTitle?.split("-")[0];
    const assessment_type = assesmentTitle?.split("-")[2];
    const sequance = assesmentTitle?.split("-")[3];
    const number_of_trials = assesmentTitle?.split("-")[4];


    // mapStateToProps

    let date = new Date();
    const [state, setState] = useState<any>({
        id: makeId(10),
        name: assesmentTitle,
        grade: "",
        type: "",
        etype: "regular",
        course: "",
        gradingcategoryid: categoryId,
        gradingperiodid: periodId,
        sequance: "",
        number_of_trials: "",
        assessment_type: "",
        assessment_prefix: "",
        startdate:
            format(new Date(), "yyyy-MM-dd") +
            "T" +
            new Date().toLocaleString()?.split(", ")[1],
        enddate:
            format(date.setDate(new Date().getDate() + 1), "yyyy-MM-dd") +
            "T" +
            new Date().toLocaleString()?.split(", ")[1],
        nooftrials: 1,
        courses: [],
        teachers: [],
        coursegroup_id: groupId,
        teacherids: [],
        grading_teachers_ids: {},
        editing_teachers_ids: {},
        submitalert: false,
        periods: [],
        questions: [],
        open: false,
        selectedgroups: [],
        selectedteachers: [],
        weeknumber: 1,
        istimed: 0,
        issecured: 0,
        isprivate: 0,
        ismandatory: 0,
        duration: 0,
        categories: [],

    });


    // karim stats 
    const [modalContent, setModalContent] = useState<any>(null);
    const [CourseUtils, SetCourseUtils] = useState<any>({
        teachers: [],
        periods: [],
        categories: [],
        coursestandards: []
    });


    const GetCourses = () => {
        axios.post(BASE_URL + "admin/getcourses.php", {
        }).then((res: any) => {
            setState((x: any) => ({ ...x, courses: res.data.data }))
        })
    }

    useEffect(() => {


        let assessment: any = null;

        if (examId && examId != "null") {
            GetAssessment(examId)
            GetCourses();
            $("#preloader").hide();
            assessment = assessment;
            if (assessment == null) {
                return;
            }
        } else {

            $("#preloader").hide();
        }
        if (assessment != null && groupId === undefined) {
            GetCourses()
            GetCategoriesTeachersPeriods(assessment.course?.coursegroup_id);
        } else {
            // in case Add 

            GetCourses();
            let date = new Date();
            if (groupId === undefined) {
                setState((prevState: any) => ({
                    ...prevState,
                    startdate:
                        format(new Date(), "yyyy-MM-dd") +
                        "T" +
                        new Date().toLocaleString()?.split(", ")[1],
                    enddate:
                        format(date.setDate(new Date().getDate() + 1), "yyyy-MM-dd") +
                        "T" +
                        new Date().toLocaleString()?.split(", ")[1],

                    teacherids: [],
                    teachers: [],
                }));
            } else {
                GetCategoriesTeachersPeriods(groupId);
            }
        }

    }, [])


    const onCloseModal = (Q: any = null) => {
        // save q in array in the store     
        setState((x: any) => ({ ...x, open: false }))
        // setState({ open: false });
    };

    const onSaveQuestion = (question: any) => {
        let tempQuestins = [...state.questions]
        let q = tempQuestins.find((q: any) => q.id == question.id);
        if (q) {
            let questions = [...state.questions];
            let index = questions.indexOf(q);
            questions[index] = question;
            setState((prev: any) => ({ ...prev, questions: questions }))

        } else {
            setState((prev: any) => ({ ...prev, questions: tempQuestins.concat(question) }))

        }
        onCloseModal();
    };

    const submitExam = () => {

        // const {
        //     selectedgroups,
        // } = state;

        const data: any = {
            sequance,
            number_of_trials,
            assessment_type,
            assessment_prefix,
            questions: state.questions,
            teacherids: state.selectedteachers,
            courses: [],
            coursegroup_id: state.coursegroup_id,
            teachers: state.selectedteachers,
        };

        const state_props = [
            "id",
            "name",
            "grade",
            "type",
            "startdate",
            "enddate",
            "nooftrials",
            "course",
            "gradingperiodid",
            "etype",
            "sequance",
            "number_of_trials",
            "assessment_type",
            "assessment_prefix",
            "gradingcategoryid",
            "selectedteachers",
            "weeknumber",
            "istimed",
            "ismandatory",
            "issecured",
            "isprivate",
            "duration",
            "questions",
        ]
        state_props.forEach((state_prop: string) => {
            if (state.hasOwnProperty(state_prop)) {
                data[state_prop] = state[state_prop];
            }
        });
        if (copy_assessment == 1 || examId == null || examId == "null" || examId == undefined) {
            AddAssessment(data);
        } else {
            UpdateAssessment(data);
        }
    }

    const removeQuest = (e: any) => {
        DeleteQuestion(e.id);
    };
    const QuestionsRenderer = (q: any) => {
        switch (q) {
            case "MCQ":
                setModalContent(<MCQComponent onSaveQuestion={(q: any) => onSaveQuestion(q)}
                coursestandards={CourseUtils.coursestandards} course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                />);
                return;
            case "OrderComponent":
                setModalContent(
                    <OrderComponent
                    coursestandards={CourseUtils.coursestandards} course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return;
            case "MatchQComponent":
                setModalContent(
                    <MatchQComponent
                    coursestandards={CourseUtils.coursestandards} course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return;
            case "ProcessCard":
                setModalContent(
                    <ProcessCardSingleState
                        type={"ProcessCard"}
                        coursestandards={CourseUtils.coursestandards} course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return;
            case "ProductCard":
                setModalContent(
                    <ProcessCardSingleState
                        type={"ProductCard"}
                        coursestandards={CourseUtils.coursestandards} course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return;
            case "LabelQComponent":
                setModalContent(
                    <LabelQComponent
                    coursestandards={CourseUtils.coursestandards} course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return;
            case "TrueOrFalse":
                setModalContent(
                    <TrueOrFalse onSaveQuestion={(q: any) => onSaveQuestion(q)} 
                    course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                    coursestandards={CourseUtils.coursestandards} />
                );
                return;
            case "EssayComponent":
                setModalContent(
                    <EssayComponent
                    coursestandards={CourseUtils.coursestandards} course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        etype={state.etype}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return;
            case "VideoComponent":
                setModalContent(
                    <VideoComponent
                    coursestandards={CourseUtils.coursestandards} course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return;
            case "AudioComponent":
                setModalContent(
                    <AudioComponent
                    coursestandards={CourseUtils.coursestandards} course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return;
            case "FileUploadEnhanced":
                setModalContent(
                    <FileUploadEnhanced
                    coursestandards={CourseUtils.coursestandards} course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return;
            case "FillTheBlank":
                setModalContent(
                    <FillTheBlank 
                    coursestandards={CourseUtils.coursestandards} course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                    onSaveQuestion={(q: any) => onSaveQuestion(q)} />
                );
                return;
            case "ImageHighlight":
                setModalContent(
                    <ImageHighlightComponent
                    coursestandards={CourseUtils.coursestandards} course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return;
            default:
                return (
                    <h1>
                        Select Question Type From The Left Menu To Add It To The
                        Assessment
                    </h1>
                );
        }
    };


    const handlecourseselect = (selectedoptions: any) => {
        GetCategoriesTeachersPeriods(selectedoptions.value);

        setState((prev: any) => ({
            ...prev,
            coursegroup_id: selectedoptions.value,

        }));
    };


    const editQuestionsRenderer = (s: any, q: any) => {
        switch (s) {
            case "MCQ":
                setModalContent(
                    <MCQComponent
                        course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        coursestandards={CourseUtils.coursestandards}
                        quest={q}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return null;
            case "OrderComponent":
                setModalContent(
                    <OrderComponent
                        course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        coursestandards={CourseUtils.coursestandards}
                        quest={q}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return null;
            case "MatchQComponent":
                setModalContent(
                    <MatchQComponent
                        course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        coursestandards={CourseUtils.coursestandards}
                        quest={q}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return null;
            case "ProcessCard":
                setModalContent(
                    <ProcessCardSingleState
                        course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        coursestandards={CourseUtils.coursestandards}
                        type={"ProcessCard"}
                        quest={q}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return null;
            case "ProductCard":
                setModalContent(
                    <ProcessCardSingleState
                        course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        coursestandards={CourseUtils.coursestandards}
                        type={"ProductCard"}
                        quest={q}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return null;
            case "LabelQComponent":
                setModalContent(
                    <LabelQComponent
                        course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        coursestandards={CourseUtils.coursestandards}
                        quest={q}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return null;
            case "TrueOrFalse":
                
                setModalContent(
                    <TrueOrFalse
                        course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        coursestandards={CourseUtils.coursestandards}
                        quest={q}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return null;
            case "EssayComponent":
                setModalContent(
                    <EssayComponent
                        course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        coursestandards={CourseUtils.coursestandards}
                        quest={q}
                        etype={state.etype}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return null;
            case "VideoComponent":
                setModalContent(
                    <VideoComponent
                        course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        coursestandards={CourseUtils.coursestandards}
                        quest={q}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return null;
            case "AudioComponent":
                setModalContent(
                    <AudioComponent
                        course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        coursestandards={CourseUtils.coursestandards}
                        quest={q}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return null;
            case "FileUploadEnhanced":
                setModalContent(
                    <FileUploadEnhanced
                        course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        coursestandards={CourseUtils.coursestandards}
                        quest={q}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return null;
            case "FillTheBlank":
                setModalContent(
                    <FillTheBlank
                        quest={q}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return null;
            case "ImageHighlight":
                setModalContent(
                    <ImageHighlightComponent
                        course_standards_targets={state.course_standards_targets?.filter((target: any) => target.standard_id == q.standardid)}
                        coursestandards={CourseUtils.coursestandards}
                        quest={q}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                    />
                );
                return null;
            default:
                return (
                    <h1>
                        Select Question Type From The Left Menu To Add It To The
                        Assessment
                    </h1>
                );
        }
    };
    const onConfirm = () => {

        history.push("/dashboard/assessments");
    };



    return (
        <MathJax>
            <div>
                <div>
                    <div className=" d-flex d-md-block flex-nowrap wrapper ">
                        <div
                            className="col-md-2 float-left col-1 pl-0 pr-0 collapse width show"
                            id="sidebar"
                        >
                            <div className="list-group border-0 card text-center text-md-left">
                                <div
                                    className="list-group-item pointer ml-3 d-inline-block collapsed"
                                    data-parent="#sidebar"
                                    onClick={() => {
                                        QuestionsRenderer("TrueOrFalse");
                                        setState((x: any) => ({ ...x, open: true }));
                                        // setState({ open: true });
                                    }}
                                >
                                    <i
                                        className="fas fa-check-circle"
                                        style={{ marginRight: "2px" }}
                                        aria-hidden="true"
                                    ></i>
                                    <i className="fas fa-times-circle" aria-hidden="true"></i>
                                    <span className="d-none d-lg-inline">+ True/False </span>
                                </div>
                                <div
                                    className="list-group-item pointer ml-3 d-inline-block collapsed"
                                    data-parent="#sidebar"
                                    onClick={() => {
                                        QuestionsRenderer("MCQ");

                                        setState((x: any) => ({ ...x, open: true }));
                                        // setState({ open: true });
                                    }}
                                >
                                    <i className="fas fa-tasks"></i>{" "}
                                    <span className="d-none d-lg-inline">+ Multiple Choice </span>
                                </div>
                                <div
                                    className="list-group-item pointer ml-3 d-inline-block collapsed"
                                    data-parent="#sidebar"
                                    onClick={() => {
                                        QuestionsRenderer("MatchQComponent");
                                        setState((x: any) => ({ ...x, open: true }));
                                        // setState({
                                        //     open: true,
                                        // });
                                    }}
                                >
                                    <i className="fas fa-bars"></i>{" "}
                                    <span className="d-none d-lg-inline">+ Matching </span>
                                </div>
                                <div
                                    className="list-group-item pointer ml-3 d-inline-block collapsed"
                                    data-parent="#sidebar"
                                    onClick={() => {
                                        QuestionsRenderer("OrderComponent");
                                        setState((x: any) => ({ ...x, open: true }));
                                        // setState({ open: true });
                                    }}
                                >
                                    <i className="fas fa-sort"></i>{" "}
                                    <span className="d-none d-lg-inline">+ Ordering </span>
                                </div>

                                <div
                                    className="list-group-item pointer ml-3 d-inline-block collapsed"
                                    data-parent="#sidebar"
                                    onClick={() => {
                                        QuestionsRenderer("EssayComponent");
                                        // setState({ open: true });
                                        setState((x: any) => ({ ...x, open: true }));
                                    }}
                                >
                                    <i className="fas fa-keyboard"></i>{" "}
                                    <span className="d-none d-lg-inline">+ Short Answer/Essay</span>
                                </div>
                                <div
                                    className="list-group-item pointer ml-3 d-inline-block collapsed"
                                    data-parent="#sidebar"
                                    onClick={() => {
                                        QuestionsRenderer("AudioComponent");
                                        // setState({ open: true });
                                        setState((x: any) => ({ ...x, open: true }));
                                    }}
                                >
                                    <i className="fas fa-microphone"></i>{" "}
                                    <span className="d-none d-lg-inline">+ Audio</span>
                                </div>
                                <div
                                    className="list-group-item pointer ml-3 d-inline-block collapsed"
                                    data-parent="#sidebar"
                                    onClick={() => {
                                        QuestionsRenderer("VideoComponent");
                                        // setState({ open: true });
                                        setState((x: any) => ({ ...x, open: true }));
                                    }}
                                >
                                    <i className="fas fa-video"></i>{" "}
                                    <span className="d-none d-lg-inline">+ Video</span>
                                </div>
                                <div
                                    className="list-group-item pointer ml-3 d-inline-block collapsed"
                                    data-parent="#sidebar"
                                    onClick={() => {
                                        QuestionsRenderer("FileUploadEnhanced");
                                        // setState({ open: true });
                                        setState((x: any) => ({ ...x, open: true }));
                                    }}
                                >
                                    <i className="fas fa-file-upload"></i>{" "}
                                    <span className="d-none d-lg-inline">+ File Upload</span>
                                </div>

                                <div
                                    className="list-group-item pointer ml-3 d-inline-block collapsed"
                                    data-parent="#sidebar"
                                    onClick={() => {
                                        QuestionsRenderer("ImageHighlight");
                                        // setState({ open: true });
                                        setState((x: any) => ({ ...x, open: true }));
                                    }}
                                >
                                    <i className="fas fa-highlighter"></i>{" "}
                                    <span className="d-none d-lg-inline">+ Highlight Image</span>
                                </div>


                                <div
                                    className="list-group-item pointer ml-3 d-inline-block collapsed"
                                    data-parent="#sidebar"
                                    onClick={() => {
                                        QuestionsRenderer("ProcessCard");
                                        setState((x: any) => ({ ...x, open: true }));
                                    }}
                                >
                                    <i className="fas fa-solid fa-chalkboard-user"></i>{" "}
                                    <span className="d-none d-lg-inline">+ Process Card</span>
                                </div>


                                <div
                                    className="list-group-item pointer ml-3 d-inline-block collapsed"
                                    data-parent="#sidebar"
                                    onClick={() => {
                                        QuestionsRenderer("ProductCard");
                                        setState((x: any) => ({ ...x, open: true }));
                                    }}
                                >
                                    <i className="fas fa-solid fa-robot"></i>{" "}
                                    <span className="d-none d-lg-inline">+ Product Card</span>
                                </div>


                            </div>
                        </div>
                        <div className="col-md-9 float-left mx-auto pl-md-2 pt-2 main">
                            <div>
                                <div className="col-12 mx-auto align-items-center bg-question rounded p-3 text-white">
                                    <NavLink to={"/dashboard/assessments"} className="btn btn-secondary mb-3">
                                        <i className="fa fa-arrow-left" aria-hidden="true" />
                                    </NavLink>
                                    <h3 className="text-shadow pl-1">Assessment Setup</h3>
                                    <div className="row">
                                        <div className="col-md-6 row align-items-start align-content-start mx-auto">
                                            <label
                                                className="align-self-start ml-1 mt-1"
                                                htmlFor="title"
                                            >
                                                Title: &nbsp;
                                            </label>
                                            <input
                                                id="title"
                                                name="title"
                                                type="text"
                                                disabled={assesmentTitle ? true : false}
                                                className="form-control"
                                                value={state?.name || ""}
                                                onChange={(e) => {
                                                    // setState({ name: e.target.value });
                                                    const new_name = e?.target?.value;

                                                    setState((x: any) => ({ ...x, name: new_name }));

                                                }}
                                            />
                                        </div>

                                        <div className="col-md-6 mt-2 align-items-start align-content-start mx-auto">
                                            <label
                                                className="align-self-start ml-1 mt-1"
                                                htmlFor="course_id"
                                            >
                                                Course: &nbsp;
                                            </label>
                                            {
                                                <Select
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: (base) => ({
                                                            ...base,
                                                            zIndex: 9999,
                                                        }),
                                                    }}
                                                    name="selectedcourse"
                                                    options={state.courses?.map((course: any) => {
                                                        return {
                                                            value: course.coursegroup_id,
                                                            label: course.name,
                                                        };
                                                    })}
                                                    className="basic-multi-select text-dark"
                                                    classNamePrefix="select"
                                                    value={state.courses
                                                        ?.map((course: any) => {
                                                            return {
                                                                value: course.coursegroup_id,
                                                                label: course.name,
                                                            };
                                                        })
                                                        ?.filter(
                                                            (op: { value: any; label: any }) =>
                                                                op?.value == state.coursegroup_id
                                                        )}
                                                    onChange={(e) => {
                                                        if (e) {
                                                            handlecourseselect(e);
                                                        }
                                                    }}
                                                />
                                            }
                                        </div>

                                        <div className="col-md-6 mt-2 row align-items-start align-content-start mx-auto">
                                            <label
                                                className="align-self-start ml-1 mt-1"
                                                htmlFor="startDate"
                                            >
                                                Start date: &nbsp;
                                            </label>
                                            <input
                                                id="startDate"
                                                name="startDate"
                                                type="datetime-local"
                                                className="form-control"
                                                value={state?.startdate ?? getToday()}
                                                onChange={(e) =>
                                                // setState({ startdate: e.target.value })
                                                {
                                                    const new_startdate = e?.target?.value;
                                                    if (new_startdate) {
                                                        setState((x: any) => ({ ...x, startdate: new_startdate }));
                                                    }
                                                }
                                                }
                                            />
                                        </div>

                                        <div className="col-md-6 mt-2 row align-items-start align-content-start mx-auto">
                                            <label
                                                className="align-self-start ml-1 mt-1"
                                                htmlFor="endDate"
                                            >
                                                End date: &nbsp;
                                            </label>
                                            <input
                                                id="endDate"
                                                name="endDate"
                                                type="datetime-local"
                                                className="form-control"
                                                value={state.enddate || ""}
                                                onChange={(e) =>
                                                // setState({ enddate: e.target.value })
                                                // setState((x: any) => ({ ...x, enddate: e.target.value }))
                                                {
                                                    const new_enddate = e?.target?.value;
                                                    if (new_enddate) {
                                                        setState((x: any) => ({ ...x, enddate: new_enddate }));
                                                    }
                                                }
                                                }
                                            />
                                        </div>

                                        <div className="col-md-6 mt-2 align-items-start align-content-start mx-auto">
                                            <label
                                                className="align-self-start mt-1 ml-1"
                                                htmlFor="selectedteachers"
                                            >
                                                Assigned teachers: &nbsp;
                                            </label>
                                            {
                                                <Select
                                                    isMulti
                                                    name="selectedteachers"
                                                    options={CourseUtils.teachers}
                                                    className="basic-multi-select text-dark"
                                                    classNamePrefix="select"
                                                    value={state.selectedteachers.length > 0 ? state.selectedteachers : CourseUtils.teachers
                                                    }
                                                    onChange={(e) => {

                                                        // setState({ selectedteachers: e });
                                                        if (e) {
                                                            setState((x: any) => ({ ...x, selectedteachers: e }))
                                                        } else {
                                                            setState((x: any) => ({ ...x, selectedteachers: [] }))
                                                        }
                                                    }}
                                                />
                                            }
                                        </div>

                                        <div className="col-md-6 mt-1 align-items-start align-content-start mx-auto">
                                            <label
                                                className="align-self-start mt-1 ml-1"
                                                htmlFor="selectedgroups"
                                            >
                                                Grading Category: &nbsp;
                                            </label>

                                            <Select
                                                name="selectedteachers"
                                                options={CourseUtils.categories?.map((cat: any) => {
                                                    return {
                                                        value: cat.id,
                                                        label: cat.name,
                                                    };
                                                })}
                                                value={CourseUtils?.categories
                                                    ?.map((cat: any) => {
                                                        return {
                                                            value: cat.id,
                                                            label: cat.name,
                                                        };
                                                    })
                                                    ?.filter(
                                                        (op: any) =>
                                                            op.value === state.gradingcategoryid
                                                    )}
                                                onChange={(e) =>
                                                // setState({ gradingcategoryid: e?.value })
                                                {

                                                    const new_gradingcategoryid = e?.value;
                                                    if (new_gradingcategoryid) {
                                                        setState((x: any) => ({ ...x, gradingcategoryid: new_gradingcategoryid }));
                                                    }
                                                }
                                                    // setState((x: any) => ({ ...x, gradingcategoryid: e?.value }))
                                                }
                                                className="basic-multi-select text-dark"
                                                classNamePrefix="select"
                                            />
                                        </div>
                                        <div className="col-md-6 mt-1 align-items-start align-content-start mx-auto">
                                            <label
                                                className="align-self-start mt-1 ml-1"
                                                htmlFor="selectedgroups"
                                            >
                                                Grading Period: &nbsp;
                                            </label>

                                            <Select
                                                name="selectedteachers"
                                                options={CourseUtils.periods?.map((period: any) => {
                                                    return {
                                                        value: period.id,
                                                        label: period.name,
                                                    };
                                                })}
                                                isDisabled={assesmentTitle ? true : false}
                                                className="basic-multi-select text-dark"
                                                classNamePrefix="select"
                                                value={CourseUtils.periods
                                                    ?.map((period: any) => {
                                                        return {
                                                            value: period.id,
                                                            label: period.name,
                                                        };
                                                    })
                                                    ?.filter(
                                                        (op: { value: any; label: any }) =>
                                                            op.value === state.gradingperiodid
                                                    )}
                                                onChange={(e) =>
                                                // setState((x: any) => ({ ...x, gradingperiodid: e?.value }))
                                                {
                                                    const new_gradingperiodid = e?.value;
                                                    if (new_gradingperiodid) {
                                                        setState((x: any) => ({ ...x, gradingperiodid: new_gradingperiodid }));
                                                    }
                                                }
                                                    // setState((x: any) => ({ ...x, gradingperiodid: e?.value }))
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6 mt-1 align-items-start align-content-start mx-auto">
                                            <label
                                                className="align-self-start mt-1 ml-1"
                                                htmlFor="selectedgroups"
                                            >
                                                Exam type : &nbsp;
                                            </label>

                                            <select disabled={state.questions.length > 0 ? true : false} className="form-control" value={state.etype}
                                                onChange={(e) => {
                                                    // setState({ etype: e?.target.value })
                                                    const new_etype = e?.target?.value;
                                                    if (new_etype) {
                                                        setState((x: any) => ({ ...x, etype: new_etype }));
                                                    }
                                                    // setState((x: any) => ({ ...x, etype: e?.target.value }))

                                                }}>
                                                <option value="regular">Ordinary  </option>
                                                <option value="capstone">Journal  </option>
                                            </select>
                                        </div>

                                        {/* Week No Input */}
                                        {state.istimed == 0 ? (
                                            <div className="col-md-6 mt-4 align-items-center align-content-center align-self-center">
                                                <label
                                                    className="align-self-center mt-1 ml-1"
                                                    htmlFor="timed"
                                                >
                                                    Timed: &nbsp;
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    name="timed"
                                                    className="align-self-start"
                                                    checked={state.istimed == 0 ? false : true}
                                                    id="timed"
                                                    onChange={(e) => {
                                                        // setState({
                                                        //     istimed: e.target.checked == false ? 0 : 1,
                                                        // });
                                                        // setState((x: any) => ({ ...x, istimed: e.target.checked == false ? 0 : 1 }))
                                                        {
                                                            const new_istimed = e?.target?.checked;
                                                            if (new_istimed) {
                                                                setState((x: any) => ({ ...x, istimed: new_istimed }));
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                                <div className="col-md-6 mt-4 align-items-center align-content-center align-self-center">
                                                    <label
                                                        className="align-self-center mt-1 ml-1"
                                                        htmlFor="timed"
                                                    >
                                                        Timed: &nbsp;
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        name="timed"
                                                        className="align-self-start"
                                                        checked={state.istimed == 0 ? false : true}
                                                        id="timed"
                                                        onChange={(e) => {
                                                            // setState({
                                                            //     istimed: e.target.checked == false ? 0 : 1,
                                                            // });
                                                            let timed = e.target.checked
                                                            setState((x: any) => ({ ...x, istimed: timed == false ? 0 : 1, }))
                                                        }}
                                                    />
                                                </div>

                                                <div className="col-md-6 mt-2 align-items-start align-content-start mx-auto">
                                                    <label
                                                        className="align-self-start mt-1 ml-1"
                                                        htmlFor="duration"
                                                    >
                                                        Duration: (minutes) &nbsp;
                                                    </label>
                                                    <input
                                                        type="number"
                                                        min={1}
                                                        className="form-control"
                                                        name="duration"
                                                        id="duration"
                                                        value={state.duration}
                                                        onChange={(e: any) =>
                                                        // setState({ duration: e.target.value })
                                                        // setState((x: any) => ({ ...x, duration: e.target.value }))
                                                        {
                                                            const new_duration = e?.target?.value;
                                                            if (new_duration) {
                                                                setState((x: any) => ({ ...x, duration: new_duration }));
                                                            }
                                                        }
                                                        }
                                                    />
                                                </div>
                                            </React.Fragment>
                                        )}

                                        <div className="col-md-6 mt-4 align-items-center align-content-center align-self-center ">
                                            <label
                                                className="align-self-center mt-1 ml-1"
                                                htmlFor="secured"
                                            >
                                                Secured: &nbsp;
                                            </label>
                                            <input
                                                type="checkbox"
                                                name="secured"
                                                className="align-self-start"
                                                checked={state.issecured == 0 ? false : true}
                                                id="secured"
                                                onChange={(e) => {
                                                    const new_issecured: any = e?.target?.checked;
                                                    setState((x: any) => ({ ...x, issecured: new_issecured }));
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-4 align-items-center align-content-center align-self-center ">
                                            <label
                                                className="align-self-center mt-1 ml-1"
                                                htmlFor="ismandatory"
                                            >
                                                Mandatory : </label>
                                            <input
                                                type="checkbox"
                                                name="ismandatory"
                                                className="align-self-start ml-1"
                                                checked={state.ismandatory == 0 ? false : true}
                                                id="ismandatory"
                                                onChange={(e) => {
                                                    const new_ismandatory: any = e?.target?.checked;
                                                    setState((x: any) => ({ ...x, ismandatory: new_ismandatory }));
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-4 align-items-center align-content-center align-self-center ">
                                            <label
                                                className="align-self-center mt-1 ml-1"
                                                htmlFor="isprivate"
                                            >
                                                Private : </label>
                                            <input
                                                type="checkbox"
                                                name="isprivate"
                                                className="align-self-start ml-1"
                                                checked={state.isprivate == 0 ? false : true}
                                                id="isprivate"
                                                onChange={(e) => {
                                                    const new_isprivate: any = e?.target?.checked;
                                                    setState((x: any) => ({ ...x, isprivate: new_isprivate }));
                                                }}
                                            />
                                        </div>


                                    </div>

                                    <h3 className="text-shadow mt-2 ml-3">Questions : {state.questions?.length}</h3>
                                    {state.questions?.map((q: any, index: number) => {
                                        return (
                                            <div
                                                className="row  mx-auto align-items-center"
                                                key={index}
                                            >
                                                <div className="col-md-10 mt-2 ">
                                                    <div
                                                        className="bg-white text-dark rounded align-items-center mt-1 pl-5 "
                                                        dangerouslySetInnerHTML={{ __html: `<p>Q${index + 1}-&nbsp;</p>` + q.header }}                        ></div>
                                                </div>
                                                <div className="col-md-1 mt-2">
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            editQuestionsRenderer(q.type, q);
                                                            // setState({ open: true });
                                                            setState((x: any) => ({ ...x, open: true }))
                                                        }}
                                                        className="btn btn-block text-white btn-warning mt-1"
                                                    >
                                                        Edit
                                                    </button>
                                                </div>
                                                <div className="col-md-1 mt-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-block text-white btn-danger mt-1"
                                                        onClick={() => removeQuest(q)}
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div className="text-center mt-5 ">
                                        <button
                                            className="btn btn-warning text-white"
                                            onClick={submitExam}
                                            disabled={state.name?.length == 0}
                                        >
                                            Submit exam
                                        </button>
                                    </div>
                                </div>
                                <Modal
                                    classNames={{
                                        modal: "customModal",
                                    }}
                                    closeOnOverlayClick={false}
                                    open={state.open}
                                    onClose={() => onCloseModal()}
                                    center
                                >
                                    {modalContent}
                                    {/*QuestionsRenderer()*/}
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div></MathJax>
    )
}
export default withRouter(assessmentComponent);
// export default assessmentComponentFuncComp